import { IComponentController } from '@wix/editor-elements-types';
import { VerticalAnchorsMenuStateRefs } from '../VerticalAnchorsMenu.types';

const compController: IComponentController<VerticalAnchorsMenuStateRefs> = {
  mapStateToProps: ({ currentPageHref }) => ({
    currentPageHref,
  }),
};

export default compController;
