import VerticalAnchorsMenu_VerticalAnchorsMenuSymbolWithTextSkinComponent from '@wix/thunderbolt-elements/src/components/VerticalAnchorsMenu/viewer/skinComps/VerticalAnchorsMenuSymbolWithTextSkin/VerticalAnchorsMenuSymbolWithTextSkin.skin';
import VerticalAnchorsMenu_VerticalAnchorsMenuSymbolWithTextSkinController from '@wix/thunderbolt-elements/src/components/VerticalAnchorsMenu/viewer/VerticalAnchorsMenu.controller';


const VerticalAnchorsMenu_VerticalAnchorsMenuSymbolWithTextSkin = {
  component: VerticalAnchorsMenu_VerticalAnchorsMenuSymbolWithTextSkinComponent,
  controller: VerticalAnchorsMenu_VerticalAnchorsMenuSymbolWithTextSkinController
};


export const components = {
  ['VerticalAnchorsMenu_VerticalAnchorsMenuSymbolWithTextSkin']: VerticalAnchorsMenu_VerticalAnchorsMenuSymbolWithTextSkin
};


// temporary export
export const version = "1.0.0"
